@import '../util.scss';

.inputGroup {
  width: 100%;
  z-index: 1000;

  .label {
    font-size: $text-sm;
    font-weight: 600;
  }

  .errorMessage {
    font-size: $text-sm;
    color: $red-600;
  }
}

.dropdownOption {
  font-size: $text-sm;
  padding: 6px 9px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownControl {
  @include transition-colors;
  border-radius: 4px;
  border: 1px solid $gray-300;
  padding: 0;
  font-size: 16px;

  &:focus {
    outline: none;
    border: 1px solid $blue-400;
  }

  &.error {
    border: 1px solid $red-500;
  }
}

.dropdownInput {}

.dropdownPlaceholder {}

.dropdownMenuPortal {
  z-index: 100 !important;
}
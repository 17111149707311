.icon,
.bubble {
  transition: all 300ms;
  filter: contrast(100%) brightness(100%);
}

.bubble {
  position: relative;
  z-index: 2;
}

.bubble::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: calc(100% - 4px);
  left: calc(50% - 4px);
  margin: auto;
  height: 8px;
  width: 8px;
  border: 1px solid #fff;
  transform: rotate(45deg);
  background-color: #fff;
  border-bottom: 1px solid rgb(136, 143, 155);
  border-right: 1px solid rgb(136, 143, 155);
}

.glow {
  filter: drop-shadow(0px 0px 3px #1c71d8);
}

.shadow {
  filter: contrast(0%) brightness(50%);
  opacity: 0.5;
}
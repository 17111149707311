@mixin inset() {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin timing-function() {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@mixin transition-colors() {
  @include timing-function;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, outline-color;
}

$text-xs: 12px;
$text-sm: 14px;
$text-base: 16px;
$text-lg: 18px;
$text-xl: 20px;
$text-2xl: 24px;
$text-3xl: 30px;
$text-4xl: 36px;
$text-5xl: 48px;
$text-6xl: 60px;
$text-7xl: 72px;
$text-8xl: 96px;
$text-9xl: 128px;

$rounded-sm: 2px;
$rounded: 4px;
$rounded-md: 6px;
$rounded-lg: 8px;
$rounded-xl: 12px;
$rounded-2xl: 16px;
$rounded-3xl: 24px;
$rounded-full: 9999px;

$shadow-sm: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
$shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
$shadow-md: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
$shadow-lg: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
$shadow-xl: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
$shadow-2xl: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
$shadow-none: drop-shadow(0 0 #0000);

$white: rgb(255, 255, 255);
$black: rgb(38, 45, 62);

$gray-50: rgb(249, 250, 251);
$gray-100: rgb(243, 244, 246);
$gray-200: rgb(229, 231, 235);
$gray-300: rgb(209, 213, 219);
$gray-400: rgb(156, 163, 175);
$gray-500: rgb(107, 114, 128);
$gray-600: rgb(75, 85, 99);
$gray-700: rgb(55, 65, 81);
$gray-800: rgb(31, 41, 55);
$gray-900: rgb(17, 24, 39);
$gray-950: rgb(3, 7, 18);

$red-50: rgb(254, 242, 242);
$red-100: rgb(254, 226, 226);
$red-200: rgb(254, 202, 202);
$red-300: rgb(252, 165, 165);
$red-400: rgb(248, 113, 113);
$red-500: rgb(239, 68, 68);
$red-600: rgb(220, 38, 38);
$red-700: rgb(185, 28, 28);
$red-800: rgb(153, 27, 27);
$red-900: rgb(124, 45, 18);
$red-950: rgb(69, 10, 10);

$yellow-50: rgb(254, 252, 232);
$yellow-100: rgb(254, 249, 195);
$yellow-200: rgb(254, 240, 138);
$yellow-300: rgb(253, 224, 71);
$yellow-400: rgb(250, 204, 21);
$yellow-500: rgb(234, 179, 8);
$yellow-600: rgb(202, 138, 4);
$yellow-700: rgb(161, 98, 7);
$yellow-800: rgb(133, 77, 14);
$yellow-900: rgb(113, 63, 18);
$yellow-950: rgb(66, 32, 6);

$green-50: rgb(240, 253, 244);
$green-100: rgb(220, 252, 231);
$green-200: rgb(187, 247, 208);
$green-300: rgb(134, 239, 172);
$green-400: rgb(74, 222, 128);
$green-500: rgb(34, 197, 94);
$green-600: rgb(22, 163, 74);
$green-700: rgb(21, 128, 61);
$green-800: rgb(22, 101, 52);
$green-900: rgb(20, 83, 45);
$green-950: rgb(5, 46, 22);

$blue-50: rgb(239, 246, 255);
$blue-100: rgb(219, 234, 254);
$blue-200: rgb(191, 219, 254);
$blue-300: rgb(147, 197, 253);
$blue-400: rgb(96, 165, 250);
$blue-500: rgb(59, 130, 246);
$blue-600: rgb(37, 99, 235);
$blue-700: rgb(29, 78, 216);
$blue-800: rgb(30, 64, 175);
$blue-900: rgb(30, 58, 138);
$blue-950: rgb(23, 37, 84);

$orange-50: rgb(255, 247, 237);
$orange-100: rgb(255, 237, 213);
$orange-200: rgb(254, 215, 170);
$orange-300: rgb(253, 186, 116);
$orange-400: rgb(251, 146, 60);
$orange-500: rgb(249, 115, 22);
$orange-600: rgb(234, 88, 12);
$orange-700: rgb(194, 65, 12);
$orange-800: rgb(154, 52, 18);
$orange-900: rgb(124, 45, 18);
$orange-950: rgb(67, 20, 7);
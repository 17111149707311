@use 'sass:color';

@import '../util.scss';

.checkboxContainer {
  @include transition-colors;
  width: 35px;
  height: 35px;
  border-radius: $rounded-full;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid $gray-600;
    border-radius: 2px;
    position: relative;

    .check {
      width: 18px;
      height: 18px;
      position: relative;
      left: -2px;
      top: -2px;
      color: $white;
    }

    &[data-checked=true] {
      background-color: $blue-500 !important;
      border-color: $blue-500;
    }
  }

  &:hover {
    background-color: color.adjust($blue-500, $alpha: -0.85);
  }
}

.button {
  @include transition-colors;
  padding: 8px 12px;
  font-size: $text-base;
  font-weight: 500;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  height: 42px;

  .content {
    display: inline-flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    &:only-child {
      margin: 0 auto;
    }
  }

  .spinner {
    @include inset;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[disabled] {
    cursor: initial;
  }

  &.loading>.content {
    opacity: 0;
  }

  &.blue {
    color: $white;
    background-color: $blue-500;
    border: 1px solid $blue-600;

    &:hover:not([disabled]) {
      background-color: $blue-600;
    }

    &[disabled]:not(.loading) {
      background-color: $blue-400;
      border: 1px solid $blue-500;
    }
  }

  &.red {
    color: $white;
    background-color: $red-500;
    border: 1px solid $red-600;

    &:hover:not([disabled]) {
      background-color: $red-600;
    }

    &[disabled]:not(.loading) {
      background-color: $red-400;
      border: 1px solid $red-500;
    }
  }

  &.green {
    color: $white;
    background-color: $green-500;
    border: 1px solid $green-600;

    &:hover:not([disabled]) {
      background-color: $green-600;
    }

    &[disabled]:not(.loading) {
      background-color: $green-400;
      border: 1px solid $green-500;
    }
  }

  &.gray {
    color: $black;
    background-color: $gray-200;
    border: 1px solid $gray-400;

    &:hover:not([disabled]) {
      background-color: $gray-300;
    }

    &[disabled]:not(.loading) {
      background-color: $gray-100;
      border: 1px solid $gray-300;
    }
  }

  &.white {
    color: $black;
    background-color: $white;
    border: 1px solid $gray-200;

    &:hover:not([disabled]) {
      background-color: $gray-100;
    }
  }

  &.rounded {
    border-radius: $rounded-full;

    &:hover:not([disabled]) {
      background-color: $gray-200;
    }
  }
}
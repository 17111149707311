@import '../util.scss';

.input {
  @include transition-colors;
  padding: 8px 10px;
  border-radius: $rounded;
  border: 1px solid $gray-300;
  width: 100%;
  font-size: $text-base;
  color: $black;
  height: 38px;

  &:focus {
    outline: none;
    border: 1px solid $blue-400;
  }

  &:hover:not(:focus):not(.error):not([disabled]) {
    border: 1px solid $gray-400;
  }

  &.error {
    border: 1px solid $red-400;
    color: $black;
  }

  &[disabled] {
    background-color: $gray-100;
  }
}

.inputGroup {
  width: 100%;

  .label {
    font-size: $text-sm;
    font-weight: 600;
  }

  .errorMessage {
    font-size: $text-sm;
    color: $red-600;
  }
}

.buttonInputGroup {
  width: 100%;
  display: flex;

  .input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .button {
    flex: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.spinner {
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
  width: 50px;
  height: 50px;
  animation: spin 700ms linear infinite;
  animation-delay: var(--spinner-delay);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
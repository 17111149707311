.grid {
  display: grid;
  width: 100%;
}

.col {
  grid-column: span 24 / span 24;
}

@media (min-width: 640px) {
  .sm-col-1 {
    grid-column: span 1 / span 1;
  }

  .sm-col-2 {
    grid-column: span 2 / span 2;
  }

  .sm-col-3 {
    grid-column: span 3 / span 3;
  }

  .sm-col-4 {
    grid-column: span 4 / span 4;
  }

  .sm-col-5 {
    grid-column: span 5 / span 5;
  }

  .sm-col-6 {
    grid-column: span 6 / span 6;
  }

  .sm-col-7 {
    grid-column: span 7 / span 7;
  }

  .sm-col-8 {
    grid-column: span 8 / span 8;
  }

  .sm-col-9 {
    grid-column: span 9 / span 9;
  }

  .sm-col-10 {
    grid-column: span 10 / span 10;
  }

  .sm-col-11 {
    grid-column: span 11 / span 11;
  }

  .sm-col-12 {
    grid-column: span 12 / span 12;
  }

  .sm-col-13 {
    grid-column: span 13 / span 13;
  }

  .sm-col-14 {
    grid-column: span 14 / span 14;
  }

  .sm-col-15 {
    grid-column: span 15 / span 15;
  }

  .sm-col-16 {
    grid-column: span 16 / span 16;
  }

  .sm-col-17 {
    grid-column: span 17 / span 17;
  }

  .sm-col-18 {
    grid-column: span 18 / span 18;
  }

  .sm-col-19 {
    grid-column: span 19 / span 19;
  }

  .sm-col-20 {
    grid-column: span 20 / span 20;
  }

  .sm-col-21 {
    grid-column: span 21 / span 21;
  }

  .sm-col-22 {
    grid-column: span 22 / span 22;
  }

  .sm-col-23 {
    grid-column: span 23 / span 23;
  }

  .sm-col-24 {
    grid-column: span 24 / span 24;
  }
}

@media (min-width: 768px) {
  .md-col-1 {
    grid-column: span 1 / span 1;
  }

  .md-col-2 {
    grid-column: span 2 / span 2;
  }

  .md-col-3 {
    grid-column: span 3 / span 3;
  }

  .md-col-4 {
    grid-column: span 4 / span 4;
  }

  .md-col-5 {
    grid-column: span 5 / span 5;
  }

  .md-col-6 {
    grid-column: span 6 / span 6;
  }

  .md-col-7 {
    grid-column: span 7 / span 7;
  }

  .md-col-8 {
    grid-column: span 8 / span 8;
  }

  .md-col-9 {
    grid-column: span 9 / span 9;
  }

  .md-col-10 {
    grid-column: span 10 / span 10;
  }

  .md-col-11 {
    grid-column: span 11 / span 11;
  }

  .md-col-12 {
    grid-column: span 12 / span 12;
  }

  .md-col-13 {
    grid-column: span 13 / span 13;
  }

  .md-col-14 {
    grid-column: span 14 / span 14;
  }

  .md-col-15 {
    grid-column: span 15 / span 15;
  }

  .md-col-16 {
    grid-column: span 16 / span 16;
  }

  .md-col-17 {
    grid-column: span 17 / span 17;
  }

  .md-col-18 {
    grid-column: span 18 / span 18;
  }

  .md-col-19 {
    grid-column: span 19 / span 19;
  }

  .md-col-20 {
    grid-column: span 20 / span 20;
  }

  .md-col-21 {
    grid-column: span 21 / span 21;
  }

  .md-col-22 {
    grid-column: span 22 / span 22;
  }

  .md-col-23 {
    grid-column: span 23 / span 23;
  }

  .md-col-24 {
    grid-column: span 24 / span 24;
  }
}

@media (min-width: 1024px) {
  .lg-col-1 {
    grid-column: span 1 / span 1;
  }

  .lg-col-2 {
    grid-column: span 2 / span 2;
  }

  .lg-col-3 {
    grid-column: span 3 / span 3;
  }

  .lg-col-4 {
    grid-column: span 4 / span 4;
  }

  .lg-col-5 {
    grid-column: span 5 / span 5;
  }

  .lg-col-6 {
    grid-column: span 6 / span 6;
  }

  .lg-col-7 {
    grid-column: span 7 / span 7;
  }

  .lg-col-8 {
    grid-column: span 8 / span 8;
  }

  .lg-col-9 {
    grid-column: span 9 / span 9;
  }

  .lg-col-10 {
    grid-column: span 10 / span 10;
  }

  .lg-col-11 {
    grid-column: span 11 / span 11;
  }

  .lg-col-12 {
    grid-column: span 12 / span 12;
  }

  .lg-col-13 {
    grid-column: span 13 / span 13;
  }

  .lg-col-14 {
    grid-column: span 14 / span 14;
  }

  .lg-col-15 {
    grid-column: span 15 / span 15;
  }

  .lg-col-16 {
    grid-column: span 16 / span 16;
  }

  .lg-col-17 {
    grid-column: span 17 / span 17;
  }

  .lg-col-18 {
    grid-column: span 18 / span 18;
  }

  .lg-col-19 {
    grid-column: span 19 / span 19;
  }

  .lg-col-20 {
    grid-column: span 20 / span 20;
  }

  .lg-col-21 {
    grid-column: span 21 / span 21;
  }

  .lg-col-22 {
    grid-column: span 22 / span 22;
  }

  .lg-col-23 {
    grid-column: span 23 / span 23;
  }

  .lg-col-24 {
    grid-column: span 24 / span 24;
  }
}

@media (min-width: 1280px) {
  .xl-col-1 {
    grid-column: span 1 / span 1;
  }

  .xl-col-2 {
    grid-column: span 2 / span 2;
  }

  .xl-col-3 {
    grid-column: span 3 / span 3;
  }

  .xl-col-4 {
    grid-column: span 4 / span 4;
  }

  .xl-col-5 {
    grid-column: span 5 / span 5;
  }

  .xl-col-6 {
    grid-column: span 6 / span 6;
  }

  .xl-col-7 {
    grid-column: span 7 / span 7;
  }

  .xl-col-8 {
    grid-column: span 8 / span 8;
  }

  .xl-col-9 {
    grid-column: span 9 / span 9;
  }

  .xl-col-10 {
    grid-column: span 10 / span 10;
  }

  .xl-col-11 {
    grid-column: span 11 / span 11;
  }

  .xl-col-12 {
    grid-column: span 12 / span 12;
  }

  .xl-col-13 {
    grid-column: span 13 / span 13;
  }

  .xl-col-14 {
    grid-column: span 14 / span 14;
  }

  .xl-col-15 {
    grid-column: span 15 / span 15;
  }

  .xl-col-16 {
    grid-column: span 16 / span 16;
  }

  .xl-col-17 {
    grid-column: span 17 / span 17;
  }

  .xl-col-18 {
    grid-column: span 18 / span 18;
  }

  .xl-col-19 {
    grid-column: span 19 / span 19;
  }

  .xl-col-20 {
    grid-column: span 20 / span 20;
  }

  .xl-col-21 {
    grid-column: span 21 / span 21;
  }

  .xl-col-22 {
    grid-column: span 22 / span 22;
  }

  .xl-col-23 {
    grid-column: span 23 / span 23;
  }

  .xl-col-24 {
    grid-column: span 24 / span 24;
  }
}

@media (min-width: 1536px) {
  .xxl-col-1 {
    grid-column: span 1 / span 1;
  }

  .xxl-col-2 {
    grid-column: span 2 / span 2;
  }

  .xxl-col-3 {
    grid-column: span 3 / span 3;
  }

  .xxl-col-4 {
    grid-column: span 4 / span 4;
  }

  .xxl-col-5 {
    grid-column: span 5 / span 5;
  }

  .xxl-col-6 {
    grid-column: span 6 / span 6;
  }

  .xxl-col-7 {
    grid-column: span 7 / span 7;
  }

  .xxl-col-8 {
    grid-column: span 8 / span 8;
  }

  .xxl-col-9 {
    grid-column: span 9 / span 9;
  }

  .xxl-col-10 {
    grid-column: span 10 / span 10;
  }

  .xxl-col-11 {
    grid-column: span 11 / span 11;
  }

  .xxl-col-12 {
    grid-column: span 12 / span 12;
  }

  .xxl-col-13 {
    grid-column: span 13 / span 13;
  }

  .xxl-col-14 {
    grid-column: span 14 / span 14;
  }

  .xxl-col-15 {
    grid-column: span 15 / span 15;
  }

  .xxl-col-16 {
    grid-column: span 16 / span 16;
  }

  .xxl-col-17 {
    grid-column: span 17 / span 17;
  }

  .xxl-col-18 {
    grid-column: span 18 / span 18;
  }

  .xxl-col-19 {
    grid-column: span 19 / span 19;
  }

  .xxl-col-20 {
    grid-column: span 20 / span 20;
  }

  .xxl-col-21 {
    grid-column: span 21 / span 21;
  }

  .xxl-col-22 {
    grid-column: span 22 / span 22;
  }

  .xxl-col-23 {
    grid-column: span 23 / span 23;
  }

  .xxl-col-24 {
    grid-column: span 24 / span 24;
  }
}
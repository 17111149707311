@import '../util.scss';

.container {
  background-color: $white;
  display: inline-block;
  border-radius: $rounded-full;
  outline: 1px solid $gray-300;
}

.button {
  font-size: $text-base;
  padding: 6px 14px;
  height: auto;
  border: none !important;

  &:not(:first-child):not(:last-child) {
    margin-left: 1px;
    margin-right: 1px;
  }

  &:last-child:nth-child(2) {
    margin-left: 1px;
  }
}

.active {
  outline: 1px solid $blue-500;
  border: none !important;
}
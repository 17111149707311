@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Tw Cen MT';
  src: url('../assets/fonts/TwCenMT-Regular.eot');
  src: url('../assets/fonts/TwCenMT-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/TwCenMT-Regular.woff2') format('woff2'),
    url('../assets/fonts/TwCenMT-Regular.woff') format('woff'),
    url('../assets/fonts/TwCenMT-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LicensePlate";
  src: url('../assets/fonts/UKNumberPlate.ttf') format('truetype');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Tw Cen MT', sans-serif;
  letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: rgb(38, 45, 62);
  background-color: rgb(238, 245, 249);
}

.rc-time-picker {
  .rc-time-picker-input {
    font-family: 'Tw Cen MT', sans-serif !important;
    font-size: 16px;
    padding: 8px 10px;
    height: auto;
  }
}

.rc-time-picker-panel {
  .rc-time-picker-panel-input {
    font-family: 'Tw Cen MT', sans-serif !important;
    font-size: 16px;
    padding: 4px 4px;
    height: auto;
  }
}

::-webkit-scrollbar {
  width: 4px;
  margin-left: 15px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: none;
  padding: 3px 0;
}

a[aria-label="Powered by Tiny"],
.tox-statusbar {
  display: none;
}

textarea {
  resize: none;
}

.ant-picker-ok {
  .ant-btn {
    background-color: rgb(37, 99, 235);
  }
}
@import '../util.scss';

.inputGroup {
  width: 100%;

  .label {
    font-size: $text-sm;
    font-weight: 600;
  }

  .errorMessage {
    font-size: $text-sm;
    color: $red-600;
  }
}

.picker {
  padding: 7px 10px;
  border-radius: $rounded;
  border: 1px solid $gray-300;
  width: 100%;

  &:global(.ant-picker-focused) {
    outline: none;
    border: 1px solid $blue-400 !important;
    box-shadow: none !important;
  }

  &:hover:not(:global(.ant-picker-focused)):not(.error) {
    border: 1px solid $gray-400;
  }
}

.popup {
  :global(.ant-picker-panel-container) {
    border-radius: $rounded-md;
    box-shadow: none;
    filter: $shadow-md;
    border: 1px solid $gray-300;
    font-family: 'Tw Cen MT';

    :global(.ant-picker-footer) {
      display: none;
    }

    :global(.ant-picker-cell) {
      padding: 4px 0;
    }
  }
}
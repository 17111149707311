@import '../util.scss';

$padding: 10px;

.card {
  @include timing-function;
  transition-property: filter;
  background-color: $white;
  border-radius: $rounded-md;
  width: 100%;
  border: 1px solid $gray-300;
  filter: $shadow-none;

  &.noPad {
    padding: 0 !important;
  }

  &.hover:hover {
    filter: $shadow-md;
    cursor: pointer;
  }

  .title {
    display: flex;
    align-items: center;
    padding: $padding $padding $padding/4 $padding;

    .icon {
      margin-right: 7px;
      width: 26px;
      height: 26px;
    }

    .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $text-base;
      font-weight: bold;
    }

    &.bordered {
      border-bottom: 1px solid $gray-300;
      padding: $padding;
    }
  }

  .body {
    padding: 0 $padding $padding $padding;

    &:first-child {
      padding: $padding;
    }

    &.noPad {
      padding: 0 0 $padding 0;
    }
  }
}
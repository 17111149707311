@import '../util.scss';

.inputGroup {
  width: 100%;

  .label {
    font-size: $text-sm;
    font-weight: 600;
  }

  .errorMessage {
    font-size: $text-sm;
    color: $red-600;
  }
}

.switch {
  background-color: #bfbfbf;
}